"use strict"; // Navigation

$(".menu-icon").on("click", function() {
  var width = $(window).width();
  $(".menu-icon").toggleClass("change");

  if (width < 768) {
    $(".mobile-menu").toggleClass("slide-in");
  } else {
    $(".desktop-menu").toggleClass("slide-in");
  }

  $("body").toggleClass("scroll-lock");
}); // Mobile navigation.

$(".dropdown").on("click", function() {
  $(".dropdown-content").slideUp();
  $(".dropdown-link").removeClass("animate-icon");
  $(this)
    .find(".dropdown-link")
    .addClass("animate-icon");

  if (
    $(this)
      .find(".dropdown-content")
      .is(":visible")
  ) {
    $(".dropdown-content").slideUp();
    $(this)
      .find(".dropdown-link")
      .removeClass("animate-icon");
  } else {
    $(this)
      .find(".dropdown-content")
      .slideDown();
  }
}); // Navigation hovers.

$(".menu-item").on("mouseenter", function() {
  var target = $(this).attr("data-target");
  $(".secondary").hide();

  if ($(this).hasClass("contains-secondary")) {
    $(".secondary").hide();
    $("." + target).fadeIn("fast");
    positionMenu($(this));
  }
}); // Navigation hovers.

$(".primary-links").on("mouseleave", function(mouse) {
  var edge = closestEdge(mouse, this);

  if (edge != "right") {
    $(".secondary").fadeOut("fast");
  }
}); // Navigation hovers.

$(".secondary-links").on("mouseleave", function(mouse) {
  $(".secondary").fadeOut("fast");
}); // Position the secondary menu.

function positionMenu(el) {
  var offset = el.offset().top;
  var parentOffset = $(".menu-flexbox-item-left").offset().top;
  offset = offset - parentOffset + 7;
  $(".secondary-links").css("top", offset);
} // See which side the mouse left an element from.

function closestEdge(mouse, elem) {
  var elemBounding = elem.getBoundingClientRect();
  var elementLeftEdge = elemBounding.left;
  var elementTopEdge = elemBounding.top;
  var elementRightEdge = elemBounding.right;
  var elementBottomEdge = elemBounding.bottom;
  var mouseX = mouse.pageX;
  var mouseY = mouse.pageY;
  var topEdgeDist = Math.abs(elementTopEdge - mouseY);
  var bottomEdgeDist = Math.abs(elementBottomEdge - mouseY);
  var leftEdgeDist = Math.abs(elementLeftEdge - mouseX);
  var rightEdgeDist = Math.abs(elementRightEdge - mouseX);
  var min = Math.min(topEdgeDist, bottomEdgeDist, leftEdgeDist, rightEdgeDist);

  switch (min) {
    case leftEdgeDist:
      return "left";

    case rightEdgeDist:
      return "right";

    case topEdgeDist:
      return "top";

    case bottomEdgeDist:
      return "bottom";
  }
} // Home sliders

$(".home-slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 5000,
  prevArrow: $(".slick-custom-prev"),
  nextArrow: $(".slick-custom-next")
});
$(".home-awards-slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  autoplay: true // prevArrow: $(".slick-custom-prev"),
  // nextArrow: $(".slick-custom-next"),
});
$(".home-certifications-slider").slick({
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 5,
  arrows: false,
  dots: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ] // prevArrow: $(".slick-custom-prev"),
  // nextArrow: $(".slick-custom-next"),
}); // Projects slider

$(".project-slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  prevArrow: $(".custom-prev"),
  nextArrow: $(".custom-next")
}); // Signature grid slider

$(".signature-slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  autoplay: true
}); // home hero slider

$(".home-hero-slider-inner").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  autoplay: true,
  fade: true,
  cssEase: "linear",
  speed: 1000
}); // Awards & Cert slider

$(".awards__slider").slick({
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  prevArrow: $(".slick-custom-prev"),
  nextArrow: $(".slick-custom-next"),
  //centerMode: true,
  responsive: [
    {
      breakpoint: 1455,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}); // Design image slider

$(".project-slider-2").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  prevArrow: $(".custom-prev"),
  nextArrow: $(".custom-next"),
  asNavFor: ".joint-text-slider",
  autoplaySpeed: 7500
}); // Design text slider

$(".joint-text-slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 7500,
  asNavFor: ".project-slider-2"
});
$(".back-to-top").click(function() {
  $("html, body").animate(
    {
      scrollTop: 0
    },
    750
  );
}); // Training & Dev number counters

$(document).ready(function() {}); // Slider height fix

function fixSlider() {
  if (document.querySelector(".slideGrab") && $(window).width() > 1023) {
    var desiredHeight = document.querySelector(".slideGrab").offsetHeight;
    var slides = document.querySelectorAll(".slide-js");
    slides.forEach(function(slide) {
      slide.style.height = desiredHeight + "px";
    });
  }
}

$(document).ready(function() {
  setTimeout(function() {
    fixSlider();
  }, 500);
});
$(window).resize(function() {
  fixSlider();
}); // Accordion

$(".accordion__upper").on("click", function(e) {
  e.preventDefault();

  if (
    $(this)
      .parent()
      .next(".accordion__lower")
      .is(":hidden")
  ) {
    $(".accordion__lower").slideUp();
    $(".accordion__icon").removeClass("accordion__icon--rotate");
  }

  $(this)
    .parent()
    .find(".accordion__icon")
    .toggleClass("accordion__icon--rotate");
  $(this)
    .parent()
    .next(".accordion__lower")
    .slideToggle();
}); // Directions accordino

$(".directions__upper").on("click", function(e) {
  e.preventDefault();

  if (
    $(this)
      .next(".directions__dropdown")
      .is(":hidden")
  ) {
    $(".directions__dropdown").slideUp();
    $(".directions__icon").removeClass("directions__icon--rotate");
  }

  $(this)
    .find(".directions__icon")
    .toggleClass("directions__icon--rotate");
  $(this)
    .next(".directions__dropdown")
    .slideToggle();
});

$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

var running1 = false;
var running2 = false;
var running3 = false;
var running4 = false;
var running5 = false;
var running6 = false;
$(window).scroll(function() {
  if (document.querySelector(".count-repeat")) {
    if ($(".count-repeat").isInViewport()) {
      if (running1 === false) {
        new Counter(".count-repeat");
        running1 = true;
      }
    }
  }

  if (document.querySelector(".count-satisfaction")) {
    if ($(".count-satisfaction").isInViewport()) {
      if (running2 === false) {
        new Counter(".count-satisfaction");
        running2 = true;
      }
    }
  }

  if (document.querySelector(".count-satisfaction2")) {
    if ($(".count-satisfaction2").isInViewport()) {
      if (running3 === false) {
        new Counter(".count-satisfaction2");
        running3 = true;
      }
    }
  }

  if (document.querySelector(".count-training")) {
    if ($(".count-training").isInViewport()) {
      if (running4 === false) {
        new Counter(".count-training");
        running4 = true;
      }
    }
  }

  if (document.querySelector(".count-courses")) {
    if ($(".count-courses").isInViewport()) {
      if (running5 === false) {
        new Counter(".count-courses");
        running5 = true;
      }
    }
  }

  if (document.querySelector(".count-trades")) {
    if ($(".count-trades").isInViewport()) {
      if (running6 === false) {
        new Counter(".count-trades");
        running6 = true;
      }
    }
  }
}); // team director drop down

$(".director__description").hide();
$(".director__wrap").on("click", function() {
  $(this)
    .next(".director__description")
    .slideToggle();
  var arrowDirection = $(this)
    .children("figure")
    .children()
    .css("transform");
  var arrowElement = $(this)
    .children("figure")
    .children();

  if (arrowDirection === "matrix(1, 0, 0, 1, 0, 0)") {
    arrowElement.css("transform", "rotate(180deg)");
  } else {
    arrowElement.css("transform", "rotate(0deg)");
  }
});
